.welcome-img {
  min-height: 100%;
  background-image: url("../images/manoslinda.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: $spacer;
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-shadow: 1px 1px 2px rgba($black, 0.6);

  .title {
    @include media-breakpoint-up("sm") {
      font-size: 5rem;
    }
  }

  .subtitle {
    padding: 0.5rem 1rem;
    border-top: $border-width solid $white;
    border-bottom: $border-width solid $white;
  }
}

.btn-scroll-down {
  color: $white;
  position: absolute;
  bottom: 20px;
  font-size: 16px;
  width: 36px;
  height: 70px;

  .icon {
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 100%;
    border: $border-width solid $white;
    border-color: $white;
    padding: $spacer / 2;
  }

  &:hover .icon {
    border-color: $black;
  }
}

.bg-primary {
  color: $white;

  .section-title {
    color: $white;
  }
}

.section-title {
  color: $primary;
  text-align: center;

  @include media-breakpoint-up("md") {
    &::before {
      content: "";
      height: 1px;
      width: 80px;
      background-color: lighten($primary, 40%);
      margin-right: 30px;
      display: inline-block;
      vertical-align: middle;
    }

    &::after {
      content: "";
      height: 1px;
      width: 80px;
      background-color: lighten($primary, 40%);
      margin-left: 30px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.img-couple {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 40px;

  @include media-breakpoint-up("sm") {
    height: 80px;
  }
}

.responsive-container {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  padding-bottom: 75%;

  .responsive-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.section-countdown {
  background-image: url("../images/ring.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: ($spacer * 6) 0;
  color: $white;
  text-shadow: 1px 1px 2px rgba($black, 0.6);

  .countdown {
    font-size: 2rem;

    @include media-breakpoint-up("sm") {
      font-size: 4rem;
    }
  }
}

.section-invitation {
  font-family: $font-calligraphy;
  padding: ($spacer * 3) 0;
  text-align: center;
  font-size: $font-size-lg;
}
