html,
body,
#root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-calligraphy;
}
