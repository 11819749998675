@import url("https://fonts.googleapis.com/css?family=EB+Garamond:500|Open+Sans&display=swap&subset=greek");

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$font-family-base: "Open Sans", sans-serif;

$font-calligraphy: "EB Garamond", serif;

$body-bg: #fcfaf5;
$body-color: $gray-700;

$primary: #458f8f;

$spacer: 1rem;
$headings-margin-bottom: $spacer * 2;
